.hover-container {
  position: relative;
  width: 200px; /* Adjust as needed */
  height: 200px; /* Adjust as needed */
}

.hover-content {
  position: relative;
  z-index: 1; /* Ensure content is above the background image */
  cursor: pointer;
  height: 100%;
}

.background-image {
  position: absolute;
  top: -20px;
  left: -40px;
  width: 250px;
  height: 250px;
  background-size: cover;
  background-position: center;
  opacity: 0;
  transition: opacity 0.3s ease; /* Smooth transition for fade effect */
}

.hover-container:hover .background-image {
  opacity: 1;
}

.box {
  width: 50px; /* Width of the bouncing box */
  height: 50px; /* Height of the bouncing box */
  position: absolute; /* Positioning so that it can be moved around */
  border-radius: 10px; /* Rounded corners */
}

.spinner {
  display: inline-block;
  width: 270px; /* Adjust the width as needed */
  height: 270px; /* Adjust the height as needed */
  position: absolute;
}

.spinner img {
  width: 100%; /* Makes the image fit the container */
  height: auto;
  animation: spin 6s linear infinite; /* Adjust the duration and easing as needed */
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

/* Changes album image preview css on hover */

.albumImage {
  transform: scale(1);
  transition: transform 0.5s, box-shadow 0.5s;
}

.albumImage:hover {
  transform: scale(1.05);
}

/* Changes project preview css on hover */

.projectPreview {
  width: 30%;
  height: 250px;
  box-shadow: 7px 5px 34px -14px rgba(0, 0, 0, 0.75);
  transition: width 0.5s, height 0.5s, box-shadow 0.5s;
}

.projectPreview:hover {
  width: 32%;
  height: 300px;
  box-shadow: 7px 9px 56px -12px rgba(0, 0, 0, 0.75);
}

.projectBlurb {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  overflow: hidden;
  width: 100%;
  height: 0;
  transition: 0.5s ease;
}

.projectPreview:hover .projectBlurb {
  height: 100%;
}

/* Makes sure CSS is reset on mobile since you can't hover */
@media screen and (max-width: 800px) {
  .projectPreview {
    width: 90%;
    height: 180px;
  }
  .projectPreview:hover {
    width: 90%;
    height: 180px;
  }
}
